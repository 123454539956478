@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200&display=swap');
* {
  font-family: 'Urbanist', sans-serif;
  /* width: 100% !important; */
  margin: 0px;
  
}
body {
  margin: 0px;
  font-family: 'Urbanist', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
}

.wholeSite {
  background-color: #305E74;
}
h1 {
  font-family: 'Urbanist', sans-serif
}

h2 {
  font-family: 'Urbanist', sans-serif
}

body #fonts {
  font-family: 'Urbanist', sans-serif
  }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon {
  transition: all .2s ease-in-out;
}

.icon:hover {
  color:#D28068 !important;
  transform: scale(1.1);
}


.navButton {
  transition: all .2s ease-in-out;
  margin-left: 10px;
  padding: 15px -10px 10px 0px;
  text-decoration: none;
}

.navButton:hover {
  color:#D28068 !important;  
}

ul {
  padding-inline-start: 0px;
}

.navigation {
  position: sticky;
  z-index:100;
  top:0;
  justify-content: right;
  /* margin: 0; */
}

.navigation:hover {
  color:#D28068; 
}

.navbar {
  background-color: hsl(199, 43%, 21%);
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.menuBars {
  margin-left: 2rem;
  margin-top: -10px;
  font-size: 2rem;
  background: none;
}

.navMenu {
  background-color: hsl(199, 43%, 21%);
  width: 200px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.navMenu.active {
  left: 0;
  transition: 350ms;
  padding-left: 0 !important;
}

.navText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 0px 8px 12px;
  list-style: none;
  height: 60px;
}

.navText a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 17px;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 18px;
  border-radius: 4px;
}

.navText a:hover {
  background-color: #142933;
  color:#D28068 !important; 
}

.navMenuItems {
  width: 100%;
}

.navbarToggle {
  background-color: hsl(199, 43%, 21%);
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.homepage {
  margin-top: -20px;
  /* margin-bottom: -60px; */
  /* padding-bottom: 40px; */
  background-color: #305E74;
}

.homeHeader {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 100vh;
  padding: 0px 95px;
  font-family: 'Urbanist', sans-serif;
  margin: auto;
  color: #DBC8B6;
  background-size: cover;
  background-image: url('https://res.cloudinary.com/dkkwdvec2/image/upload/v1656781945/Artboard_1_copy_joxw9r.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  min-width: 300px;
}

@media only screen and (max-width: 700px) {.homeHeader {
  padding: 0px 50px;
  /* background-image: url('https://res.cloudinary.com/dkkwdvec2/image/upload/v1656780750/Artboard_1_copy_i4xv5w.png'); */

}}

.headerDesc {
  display: block;
  text-align: left;
}
.contactButton {
  display: flex;
  flex-shrink: 1;
  border: 1px solid white;
  margin-top: 20px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #DBC8B6;
  cursor: pointer;
  display: inline-block;
  font-family: 'Urbanist', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  background: #305E74;
  font-size: 20px;
  /* position: absolute; */
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  text-align: center;
}

.contactButton:hover,
.contactButton:active {
  background-color: #DBC8B6;
  background-position: 0 0;
  color: #366981;
}

.contactButton:active {
  opacity: .5;
}

.designPortfolioContainer {
  /* height: 100%; */
  color: #DBC8B6;
  /* margin: 0px 95px; */
  min-width: 300px;
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* flex-shrink: 4; */
}

@media only screen and (max-width: 1500px) {.designPortfolioContainer {
  /* margin: 0px 50px; */
  min-width: 100vw;
}}

.designImgContainer {
  min-width: 200px;
  max-width: 375px;
  margin: 10px 20px 10px 20px;
  background-color: rgba(22, 81, 126, 0.3);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  /* width: 40vw; */
}

@media only screen and (max-width: 1400px) {.designImgContainer {
  max-width: 450px;
  min-width: 50px;
  margin: 10px 10px 10px 10px;
}}


@media only screen and (max-width: 680px) {.designImgContainer {
  width: 80vw;
  margin: 10px 10px 10px 10px;
}}

.links {
  color:#D28068; 
}

.links:hover {
  color:#e4dad0; 
}

.dates {
  display: flex;
  flex-direction: column-reverse;
  flex: 1;
  align-items: flex-end;
}

.bullets {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  width: 100%;
  align-items: flex-start;
  align-content: flex-start;
}

#favicon {
  color: red;
}

.devProjectsContainer {
  min-width: 300px;
  display: flex; 
  flex-direction: column;
  flex-wrap: wrap;
  flex-shrink: 4;
  background-color: #e4dad0;
  width: 40vw;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  /* margin: auto; */
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px; */
}
@media only screen and (max-width: 680px) {.devProjectsContainer {
  width: 80vw;
  margin-right: 10;
  margin-left: 10;
  margin-bottom: 0;
}}



.devProjectsBoxes {
  display: flex; 
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 0;
  /* margin: auto; */
}

.imageContainer {
  position: relative;
  max-width: 100%;
  /* max-height: 330px; */
  /* max-width: 400px; */
  align-self: center;
  /* flex-grow: 1; */
  margin: auto;
  /* padding: 10px; */
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(55, 104, 129, 0.8);
  color: #f1f1f1;
  transition: .5s ease;
  font-size: 20px;
  text-align: center;
  padding: 0px;
}

.imageContainer:hover .overlay {
  opacity: 1;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(55, 104, 129, 0.8);
  color: #f1f1f1;
  transition: .5s ease;
  font-size: 20px;
  text-align: center;
  padding: 0px;
  width: 100%;
  height: auto;
}

.projectImage {
  display: block;
  width: 100%;
  height: auto;
  align-self: center;
}

.textArea {
  margin: 10px 0px;
  min-height: 145px;
}

@media only screen and (min-width: 1425px) {.textArea {
  flex-basis: 30%;
}}

@media only screen and (max-width: 680px) {.textArea {
  flex-basis: 30%;
}}

.overlay:hover {
  opacity:0;
  background: rgba(55, 104, 129, 0.0);
}
.devButton {
  display: flex;
  flex-shrink: 1;
  border: 1px solid white;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #DBC8B6;
  cursor: pointer;
  display: inline-block;
  font-family: 'Urbanist', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  background: #305E74;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.devButton:hover,
.devButton:active {
  background-color: #DBC8B6;
  background-position: 0 0;
  color: #366981;
}

.devButton:active {
  opacity: .5;
}


.devProjects {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #DBC8B6;
  padding: 20px 0px
}
.resumeContainer {
  /* padding: 100px 0px; */
  /* height: 100%; */
  color: #DBC8B6;
  /* background-color: blueviolet; */
  /* margin: 0px 95px; */
  min-width: 300px;
  text-align: center;
  background-size: cover;
  background-image: url('https://res.cloudinary.com/dkkwdvec2/image/upload/v1649778612/Triangle_Accents_dxoaun.png');
  background-repeat: no-repeat;
  min-height: 100vh;

}
@media only screen and (max-width: 700px) {.jobScrollArea {
  min-width: 50px;
}}

.resume {
  height: 100vh;
  color: #DBC8B6;
  /* margin: 95px 0px; */
  min-height: 300px;
  display: flex;
  flex-shrink: 2;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* background-attachment: fixed; */
  border-top: 4px solid rgba(14, 51, 80, 0.3);;
}

a.item.navButton {
  max-width: 100vw;
  /* padding-right: 0px !important; */
  margin: 0px !important;
}

.jobScrollArea {
  display: flex;
  flex-direction: row;
  width: 100vw;
}
@media only screen and (max-width: 700px) {.jobScrollArea {
  display: flex;
  flex-direction: column;
}}
.jobDots {
  /* height: 15px; */
  /* background-color: #DBC8B6; */
  color: #DBC8B6;
  margin-right: 10px;
  padding-bottom: 5px;
}

.act, .jobDots:hover {
  color: #D28068;
  cursor: pointer;
}

@media only screen and (max-width: 400px) {.jobDots {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #DBC8B6;
  margin-right: 10px;
}}



@media only screen and (max-width: 400px) {.act, .jobDots:hover {
  background-color: #D28068;
  cursor: pointer;
  width: 18px;
  height: 18px;
}}

.border {
  padding: 10px 10px 0px 10px;
  width: 170px;
  margin-right: 10px; 
  text-align: left;
}
@media only screen and (max-width: 400px) {.border {
  padding: 10px 10px 10px 10px;
  width: fit-content;
  margin-right: 5px; 
  text-align: left;
}}
.act .jobDots{
  /* padding-bottom: 5px; */
  border-bottom: 2px solid #D28068;
}

.bg, .border:hover{
  background-color: rgba(14, 51, 80, 0.3);
}

@media only screen and (max-width: 400px) {.bg, .border:hover{
  background-color: transparent;
}}


.jobDotsBox {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: fit-content;
  /* width: 190px; */
  /* margin-bottom: 20px; */
  margin-left: 20%;
  margin-right: 10px;
  padding-left: 10px;
  border-left: 2px solid #D28068;
}

@media only screen and (max-width: 700px) {.jobDotsBox {
  display: flex;
  flex-direction: row;
  margin-left: 10%;
  margin-bottom: 10px;
}}

.jobsSlider {
  display: flex;
  justify-content: flex-start;
  flex-flow: column wrap;
  align-items: flex-start;
  align-content: flex-start;
  flex-grow: 4;
  min-height: 200px;
  animation-duration: .75s;
  animation-name: slidein;
  margin-right: 20%;
}

.bulletPoint {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* margin: 5px; */
}

@media only screen and (max-width: 700px) {.jobsSlider {
  margin-left: 10% !important;
  margin-right: 10%;
  min-height: 450px !important;
}}
@media only screen and (max-width: 1200px) {.jobsSlider {
  min-height: 400px;
}}


@keyframes slidein {
  from {
    /* margin-left: 90%;
    width: 80%; */
    transform: translateX(100%);
    opacity: 0.25;
  }

  to {
    /* margin-left: 90%;
    width: 100%; */
    transform: translateX(0%);
    opacity: 1;
  }
}
.jobBullets {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  background-color: rgba(14, 51, 80, 0.3);
  color: white;
  bottom: 0;
  /* margin-top: 20px; */
  padding: 20px 0px;
  /* margin-bottom: 40px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  z-index:100;
}